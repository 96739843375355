import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import Container from '../container';
import Row from '../row';
import Col from '../col';
import Button from '../button';
import Social from '../social';
import Copyright from '../copyright';
import Modal from '../modal';
import Logo from '../../images/inove.png';
import api from '../../api';
import './footer.scss';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      endereco: '',
      social: []
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    api.get('/endereco').then(res => {
      this.setState({
        endereco: res.data.texto
      });
    });
    api.get('/social').then(res => {
      this.setState({
        social: res.data
      });
    });
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <footer className="footer">
        <Container>
          <hr />
          <div className="wrapper-contact">
            <Row center>
              <Col size={6}>Ainda não usa o Otek?</Col>
              <Col size={6} style={{ textAlign: 'right' }}>
                <Button onClick={this.openModal}>Entre em contato</Button>
              </Col>
            </Row>
            <Modal isOpen={this.state.isOpen} onClose={this.closeModal} />
          </div>
          <hr />
          <Row style={{ paddingTop: 30, paddingBottom: 30 }}>
            <Col size={6}>
              <div className="iconFooter">
                <a
                  href="https://inovesistemas.info"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Inove Sistemas"
                >
                  <img src={Logo} alt="Inove Sistemas" />
                </a>
              </div>
            </Col>
            <Col size={6} style={{ textAlign: 'right' }}>
              <div className="contactFooter">
                <div>{renderHTML(this.state.endereco)}</div>
                <div>
                  {this.state.social.map(social =>
                    social.value ? (
                      <Social key={social.id} name={social.name}>
                        {social.value}
                      </Social>
                    ) : (
                      ''
                    )
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Copyright />
      </footer>
    );
  }
}

export default Footer;
